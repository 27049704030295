<template>
  <div class="flex flex-col bg-white border px-8 py-4 w-2/3">
    <div class="my-4 flex justify-between items-center font-semibold text-lg">
      <el-input
        class="mr-4"
        placeholder="nom du compte"
        v-model="listQuery.search"
        @keydown.native.enter="fetchAccounts()"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-button type="primary" @click="handleNewAccount"
        >Créer un compte</el-button
      >
    </div>
    <skeleton-list :row="8" v-if="listLoading" />
    <div v-else>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="flex justify-between items-center mb-2 pb-4 border-b"
      >
        <div class="flex flex-col">
          <h5 class="text-gray-700 text-base font-semibold">
            {{ item.account_name }}
          </h5>
          <div class="text-sm text-gray-400">Numero : {{ item.numero }}</div>
          <div class="flex justify-evenly text-sm mt-1 text-blue-400">
            <a class="pr-1 cursor-pointer" @click="handleUpdate(item)"
              ><i class="el-icon-edit-outline"></i> Modifier</a
            >
            <span class="px-1"
              ><i class="el-icon-folder"></i> {{ item.type_account }}</span
            >
            <span
              @click="changeStatut(item)"
              class="text-green-400 cursor-pointer"
              v-if="!item.is_lock"
              ><i class="el-icon-unlock"></i> Active</span
            >
            <span
              @click="changeStatut(item)"
              class="text-red-400 cursor-pointer"
              v-if="item.is_lock"
              ><i class="el-icon-lock"></i> Désactive</span
            >
          </div>
        </div>
        <div class="text-gray-700 font-semibold">
          {{ item.balance | moneyFilter }} {{item.currency}}
        </div>
      </div>
    </div>

    <!-- Transfert en facture -->
    <el-dialog
      :title="
        dialogStatut === 'create' ? 'Ajouter un compte' : 'Modifier un compte'
      "
      :visible.sync="dialogVisibleAccount"
      width="40%"
      top="9vh"
      :close-on-click-modal="false"
    >
      <el-form
        :model="account"
        :rules="rule"
        ref="accountForm"
        :label-position="labelPosition"
      >
        <el-form-item prop="type_account" label="Type de compte">
          <el-select
            style="width: 100%"
            v-model="account.type_account"
            placeholder="Choisir"
            clearable
            filterable
          >
            <el-option
              v-for="item in types"
              :key="item.id"
              :label="`${item.name}`"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="numero" label="Numéro compte">
          <el-input v-model="account.numero"> </el-input>

          <el-button
            v-if="account.numero.length === 0"
            @click="handleGenerateAccount"
            :loading="loadingGN"
            type="text"
            >Generer le numéro</el-button
          >
        </el-form-item>
        <el-form-item prop="name" label="Intitulé">
          <el-input v-model="account.name"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-switch v-model="account.is_lock" active-text="Activer le compte">
            Activer le compte</el-switch
          >
        </el-form-item>
      </el-form>

      <div slot="footer" class="border-t pt-2 dialog-footer">
        <el-button
          type="primary"
          :loading="loadingCompte"
          @click="dialogStatut === 'create' ? createData() : updateData()"
          >Enregistrer</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  generateAccount,
  createAccounts,
  fetchAllAccounts,
  fetchBalances,
  updateAccounts,
  updateStatus,
} from "@/api/account";
import { toThousandFilter } from "@/Filters";
import { parseDate } from "@/utils";
import SkeletonList from "@/components/Skeleton/list";
export default {
  name: "List-Account",
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  components: { SkeletonList },
  data() {
    return {
      labelPosition: "top",
      dialogVisibleAccount: false,
      items: [],
      totalBalance: 0,
      totalCustomerDbt: 0,
      loadingGN: false,
      loadingCompte: false,
      listLoading: false,
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        search: undefined,
        sort: "desc",
        sortDir: "createdAt",
      },
      types: [
        {
          name: "Espèce",
          value: "CASH",
        },
        {
          name: "Compte bancaire",
          value: "BANK_ACCOUNT",
        },
        {
          name: "Carte bancaire",
          value: "BANK_CARD",
        },
        {
          name: "Mobile Money",
          value: "WALLET",
        },
      ],
      dialogStatut: "create",
      account: {
        account_id: undefined,
        balance: undefined,
        name: "",
        is_lock: false,
        type_account: "",
        numero: "",
      },
      rule: {
        name: [
          {
            required: true,
            message: "Le champs est obligatoire",
            trigger: "blur",
          },
        ],
        type_account: [
          {
            required: true,
            message: "Le champs est obligatoire",
            trigger: "change",
          },
        ],
        numero: [
          {
            required: true,
            message: "La date est obligatoire",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.fetchAccounts();
    this.getAllBalances();
  },
  methods: {
    handleNewAccount() {
      this.account = {
        account_id: undefined,
        name: "",
        is_lock: false,
        type_account: "",
        numero: "",
      };

      this.$nextTick(() => {
        this.$refs["accountForm"].clearValidate();
      });
      this.dialogVisibleAccount = true;
    },

    async handleGenerateAccount() {
      this.loadingGN = true;
      await generateAccount().then((res) => {
        console.log(res.data);
        setTimeout(() => {
          this.account.numero = res.data.numero;
          this.loadingGN = false;
        }, 1.5 * 1000);
      });
    },
    createData() {
      this.$refs["accountForm"].validate((valid) => {
        if (valid) {
          this.loadingCompte = true;
          createAccounts(this.account).then((res) => {
            setTimeout(() => {
              console.log(res.data);
              this.items.unshift(Object.assign({}, res.data));
              this.loadingCompte = false;
              this.dialogVisibleAccount = false;
              this.$message({
                message: "Le compte a été ajouter.",
                type: "success",
              });
            }, 1.5 * 1000);
          });
        }
      });
    },

    async fetchAccounts() {
      this.listLoading = true;
      await fetchAllAccounts(this.listQuery).then((res) => {
        setTimeout(() => {
          this.items = res.data.items;
          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;
          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    async getAllBalances() {
      await fetchBalances().then((res) => {
        this.totalBalance = res.data.total_balance;
        this.totalCustomerDbt = res.data.total_customer_debt;
      });
    },

    handleUpdate(e) {
      this.dialogStatut = "edit";
      this.account.account_id = e.account_id;
      this.account.name = e.account_name;
      this.account.type_account = e.type_account;
      this.account.numero = e.numero;
      this.account.is_lock = e.is_lock;
      this.account.balance = e.balance;

      this.$nextTick(() => {
        this.$refs["accountForm"].clearValidate();
      });
      this.dialogVisibleAccount = true;
    },
    updateData() {
      this.$refs["accountForm"].validate((valid) => {
        if (valid) {
          this.loadingCompte = true;
          updateAccounts(this.account.account_id, this.account)
            .then(() => {
              setTimeout(() => {
                const index = this.items.findIndex(
                  (el) => el.account_id === this.account.account_id
                );
                this.items.splice(index, 1, {
                  account_id: this.account.account_id,
                  account_name: this.account.name,
                  type_account: this.account.type_account,
                  numero: this.account.numero,
                  is_lock: this.account.is_lock,
                  balance: this.account.balance,
                });
                // this.items.unshift(Object.assign({}, res.data));
                this.loadingCompte = false;
                this.dialogVisibleAccount = false;
                this.$message({
                  message: "Le compte a été ajouter.",
                  type: "success",
                });
              }, 1.5 * 1000);
            })
            .catch((err) => {
              console.log(err);
              this.dialogVisibleAccount = false;
            });
        }
      });
    },
    changeStatut(row) {
      updateStatus(row.numero).then(() => {
        row.is_lock = !row.is_lock;
        this.$message({
          message: "Le statut a été modifier.",
          type: "success",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
