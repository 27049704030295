<template>
  <div class="bg-white border p-4">
    <div class="flex">
      <el-input
        class="mr-4 flex-1"
        placeholder="Entrer reference "
        v-model="listQuery.ref"
        @keydown.native.enter="getOperationData()"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-select
        class="mr-2 flex-1"
        v-model="listQuery.account"
        placeholder="Choisir le compte"
        clearable
        filterable
        @change="getOperationData()"
      >
        <el-option
          v-for="item in accounts"
          :key="item.account_id"
          :label="`${item.account_name}`"
          :value="item.numero"
        >
        </el-option>
      </el-select>
      <el-select
        class="mr-2 flex-1"
        v-model="listQuery.raison"
        placeholder="Raison"
        clearable
        filterable
        @clear="handleClearRaison"
        @change="getOperationData()"
      >
        <el-option
          v-for="item in raisonsList"
          :key="item.name"
          :label="`${item.value}`"
          :value="item.name"
        >
        </el-option>
      </el-select>

      <el-date-picker
        class="mr-2 w-1/4"
        v-model="listQuery.start"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="Date debut"
      >
      </el-date-picker>
      <el-date-picker
        class="mr-2 w-1/4"
        v-model="listQuery.end"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="Date fin"
      >
      </el-date-picker>
      <el-button @click="getOperationData">Rechercher</el-button>
    </div>

    <div class="customer-white mt-10">
      <div class="flex justify-between mb-4">
        <el-select
          v-model="listQuery.type"
          placeholder="Choisir le type"
          @change="handleTypes"
        >
          <el-option
            v-for="item in typeOp"
            :key="item.name"
            :label="`${item.value}`"
            :value="item.name"
          >
          </el-option>
        </el-select>

        <div>
          <el-button
            icon="el-icon-plus"
            type="primary"
            @click="handleTransaction()"
            >Transaction</el-button
          >
          <!--   <el-button @click="handleTransfert()" icon="el-icon-refresh" plain
            >Transfert</el-button
          > -->
        </div>
      </div>

      <operation-list :items="items" :loading="listLoading" />
    </div>
    <div class="flex justify-end">
      <pagination
        v-show="listQuery.total > 0"
        :total="listQuery.total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.size"
        @pagination="getOperationData()"
      />
    </div>

    <el-dialog
      title="Nouvelle transaction"
      width="35%"
      top="4vh"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleTransfert"
    >
      <el-form
        :model="transfert"
        :rules="rule"
        ref="refNewTransaction"
        :label-position="labelPosition"
      >
        <el-form-item label="Type de transaction " prop="type">
          <el-select
            style="width: 100%"
            v-model="transfert.type"
            placeholder="Choisir le type"
            clearable
            filterable
          >
            <el-option
              v-for="item in types"
              :key="item.name"
              :label="`${item.value}`"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="transfert.type === 'TRANSFER'"
          label="Compte émeteur "
          prop="account_from"
        >
          <el-select
            style="width: 100%"
            v-model="transfert.account_from"
            placeholder="Choisir le compte"
            clearable
            filterable
            @change="handleAccountFrom"
          >
            <el-option
              v-for="item in accounts"
              :key="item.account_id"
              :label="`${item.account_name}`"
              :value="item.numero"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="transfert.type === 'APPROV' || transfert.type === 'WITHDRAW'"
          :label="
            transfert.type === 'APPROV' ? 'Compte réceveur' : 'Compte source'
          "
          prop="account_to"
        >
          <el-select
            style="width: 100%"
            v-model="transfert.account_to"
            placeholder="Choisir le compte"
            @change="handleAccountTo"
          >
            <el-option
              v-for="item in accounts"
              :key="item.account_id"
              :label="`${item.account_name}`"
              :value="item.numero"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- Client -->
        <el-form-item
          v-if="
            transfert.type === 'APPROV_CUSTOMER' ||
            transfert.type === 'WITHDRAW_CUSTOMER'
          "
          label="Compte créditeur client"
          prop="account_from"
        >
          <el-select
            style="width: 100%"
            v-model="transfert.account_from"
            placeholder="Choisir le compte"
            clearable
            filterable
          >
            <el-option
              v-for="item in accountContact"
              :key="item.account_id"
              :label="`${item.account_name}`"
              :value="item.numero"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="
            transfert.type === 'APPROV_CUSTOMER' ||
            transfert.type === 'WITHDRAW_CUSTOMER'
          "
          label="Compte banque ou wallet"
          prop="account_to"
        >
          <el-select
            style="width: 100%"
            v-model="transfert.account_to"
            placeholder="Choisir le compte"
            clearable
            filterable
          >
            <el-option
              v-for="item in accounts"
              :key="item.account_id"
              :label="`${item.account_name}`"
              :value="item.numero"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="amount" label="Montant">
          <el-input-number
            style="width: 100%"
            :controls="false"
            v-model="transfert.amount"
          >
          </el-input-number>
        </el-form-item>

        <el-form-item prop="describe" label="Note" class="flex-1">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 4 }"
            placeholder="Ecrire ici"
            v-model="transfert.describe"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="border-t pt-2 dialog-footer">
        <el-button
          type="primary"
          :loading="loadingOp"
          :disabled="transfert.amount <= 0"
          @click="createNewTransaction('refNewTransaction')"
          >Enregistrer</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {
  fetchAccounts,
  fetchAccountContact,
  fetchAllOperation,
  newTransaction,
} from "@/api/account";

import OperationList from "@/components/Operation";

export default {
  name: "Operation",
  components: { Pagination, OperationList },

  data() {
    return {
      listLoading: false,
      raisonsList: [
        {
          name: "PAYMENT_INVOICE",
          value: "Paiement facture",
        },
        {
          name: "TRANSFERT",
          value: "Transfert C2C",
        },
        {
          name: "EXPENSE",
          value: "Depense",
        },
        {
          name: "PAYMENT_POS",
          value: "Paiement P.Vente",
        },
        {
          name: "CANCEL_TRANSACTION",
          value: "Annulation",
        },
        {
          name: "PAYMENT_CREDIT_NOTE",
          value: "Paiement Avoir",
        },
        {
          name: "PAYEMENT_SALARY",
          value: "Paiement salaire",
        },
        {
          name: "OTHER",
          value: "Autre",
        },
      ],
      typeOp: [
        {
          name: undefined,
          value: "Toute",
        },
        {
          name: "DEBIT",
          value: "Encaissement ",
        },
        {
          name: "CREDIT",
          value: "Décaissement ",
        },
      ],
      types: [
        {
          name: "TRANSFER",
          value: "Transfert compte à compte",
        },
        {
          name: "APPROV",
          value: "Approvissionnement ",
        },
        {
          name: "WITHDRAW",
          value: "Retrait ",
        },
        {
          name: "APPROV_CUSTOMER",
          value: "Versement client",
        },
        {
          name: "WITHDRAW_CUSTOMER",
          value: "Remboursement client",
        },
      ],
      items: [],
      accounts: [],
      accountContact: [],
      dialogVisibleOperation: false,
      dialogVisibleTransfert: false,
      labelPosition: "top",
      loadingOp: false,
      operation: {
        account_to: "",
        amount: 0,
        describe: "",
        type: "",
      },
      transfert: {
        account_from: "",
        account_to: "",
        amount: 0,
        type: "TRANSFER",
        describe: "",
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        start: undefined,
        end: undefined,
        ref: undefined,
        account: undefined,
        type: undefined,
        raison: undefined,
        sort: "desc",
        sortDir: "createdAt",
      },

      rule: {
        account_from: [
          {
            required: true,
            message: "Le compte est obligatoire",
            trigger: "change",
          },
        ],
        account_to: [
          {
            required: true,
            message: "Le compte est obligatoire",
            trigger: "change",
          },
        ],
        account_form: [
          {
            required: true,
            message: "Le compte est obligatoire",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "Le type est obligatoire",
            trigger: "change",
          },
        ],
        amount: [
          {
            required: true,
            message: "La montant est obligatoire",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getOperationData();
    this.getAccounts();
    this.getAccountContact();
  },
  methods: {
    async getAccounts() {
      await fetchAccounts()
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAccountContact() {
      await fetchAccountContact()
        .then((res) => {
          this.accountContact = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getOperationData() {
      this.listLoading = true;
      await fetchAllOperation(this.listQuery).then((res) => {
        setTimeout(() => {
          this.items = res.data.items;
          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;
          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleClearRaison() {
      this.listQuery.raison = undefined;
    },
    handleTypes() {
      this.getOperationData();
    },
    resetTransfert() {
      this.transfert = {
        account_from: "",
        account_to: "",
        amount: 0,
        type: "TRANSFER",
        describe: "",
      };
    },

    handleTransaction() {
      this.resetTransfert();
      this.$nextTick(() => {
        this.$refs["refNewTransaction"].clearValidate();
      });
      this.dialogVisibleTransfert = true;
    },

    createNewTransaction(refNewTransaction) {
      this.$refs[refNewTransaction].validate((valid) => {
        if (valid) {
          this.loadingOp = true;
          newTransaction(this.transfert)
            .then(() => {
              setTimeout(() => {
                this.loadingOp = false;
                this.dialogVisibleTransfert = false;
                this.$message({
                  message: "Operation enregistrée.",
                  type: "success",
                });
                this.getOperationData();
              }, 1.5 * 1000);
            })
            .catch((err) => {
              setTimeout(() => {
                this.loadingOp = false;
                var messageError = "";
                if (err.data.status === 409 || err.data.status === 404) {
                  messageError = err.data.message;
                }
                if (err.data.status === 400) {
                  messageError = "Veuillez remplir le champs obligatoire";
                }
                if (err.data.status === 500) {
                  messageError = "Erreur Serveur";
                }
                this.$message({
                  message: messageError,
                  type: "error",
                });
              }, 1.5 * 1000);
            });
        }
      });
    },

    handleAccountFrom(e) {
      if (e === this.transfert.account_to) {
        this.transfert.account_from = "";
        this.$message({
          message: "Selectionner une compte different.",
          type: "warning",
        });
      }
    },
    handleAccountTo(e) {
      if (e === this.transfert.account_from) {
        this.transfert.account_to = "";
        this.$message({
          message: "Selectionner une compte different.",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
