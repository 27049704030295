<template>
  <div>
    <skeleton-list :row="8" v-if="loading" />

    <el-table :data="items" v-else style="width: 100%">
      <el-table-column label="DATE" width="180">
        <template slot-scope="{ row }">
          <span>{{ row.date }}</span>
        </template>
      </el-table-column>

      <el-table-column label="TYPE" width="140">
        <template slot-scope="{ row }">
          <div v-if="row.type === 'DEBIT'">Encaissée</div>
          <div v-if="row.type === 'CREDIT'">Décaissée</div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="REFERENCE" width="230">
      <template slot-scope="{ row }">
        <span class="text-blue">{{ row.reference }}</span>
      </template>
    </el-table-column> -->
      <el-table-column label="MOTIF" width="180">
        <template slot-scope="{ row }">
          <span>{{ row.raison }}</span>
        </template>
      </el-table-column>

      <el-table-column label="COMPTE">
        <template slot-scope="{ row }">
          <div class="text-blue">
            <span>{{ row.account_name }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="" label="LIBELLE">
        <template slot-scope="{ row }">
          <div>
            {{ row.describe }}
          </div>
        </template>
      </el-table-column>

      <el-table-column width="170" label="MONTANT" align="right">
        <template slot-scope="{ row }">
          <span class="">{{ row.amount | moneyFilter }} </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="total_all_taxe"
        label="SOLDE"
        width="170"
        align="right"
      >
        <template slot-scope="{ row }">
          <span class="">{{ row.balance | moneyFilter }} </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { toThousandFilter } from "@/Filters";
import { parseTime } from "@/utils";
import SkeletonList from "@/components/Skeleton/list";
export default {
  name: "COMPONENT-OPERATION",
  filters: {
    parseTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { SkeletonList },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
