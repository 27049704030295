<template>
  <div class="flex flex-col font-Inter px-10 mb-10">
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        >
          <h2 class="text-xl leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Gestion de la trésorie
          </h2>
        </div>
        <el-select
          v-model="typeSearch"
          class="mr-2"
          placeholder="Sectionner la date"
          @change="HANDLE_PLAGE"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="flex">
      <div
        style="color: #135200; background-color: #b7eb8f"
        class="w-1/2 flex flex-col justify-center px-8 py-4 rounded shadow mr-5"
      >
        <skeleton-list :row="3" v-if="loadingStat" />
        <div v-if="!loadingStat" class="text-3xl text-gr font-bold">
          {{ Math.abs(totalDebit) | moneyFilter }} {{user.currency_code}}
        </div>
        <div v-if="!loadingStat" class="mt-2">
          <i class="el-icon-bottom"></i> Encaissement {{ lableStat }}
        </div>
      </div>

      <div
        style="color: #871400; background-color: #ffbb96"
        class="w-1/2 flex flex-col justify-center px-8 py-4 rounded shadow mr-5"
      >
        <skeleton-list :row="3" v-if="loadingStat" />
        <div v-if="!loadingStat" class="text-3xl text-gr font-bold">
          {{ Math.abs(totalCredit) | moneyFilter }} {{user.currency_code}}
        </div>
        <div v-if="!loadingStat" class="mt-2">
          <i class="el-icon-top"></i> Decaissement {{ lableStat }}
        </div>
      </div>

      <div
        style="color: #00474f; background-color: #87e8de"
        class="w-1/2 bg-white flex flex-col justify-center px-8 py-4 rounded border mr-1"
      >
        <skeleton-list :row="3" v-if="loadingStat" />
        <div v-if="!loadingStat" class="text-3xl text-gr font-bold">
          {{ totalBalance | moneyFilter }} {{user.currency_code}}
        </div>
        <div v-if="!loadingStat" class="mt-2">
          <i class="el-icon-wallet"></i> Total des montants disponible
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName" class="mt-4">
      <el-tab-pane label="Transactions" name="OPERATION">
        <Operation v-if="activeName === 'OPERATION'" />
      </el-tab-pane>
      <el-tab-pane label="Comptes" name="ACCOUNT">
        <AccountList v-if="activeName === 'ACCOUNT'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { fetchBalances, fetchStastic } from "@/api/account";
import AccountList from "./account";
import Operation from "./operation.vue";
import { toThousandFilter } from "@/Filters";
import SkeletonList from "@/components/Skeleton/list";
import {
  parseDate,
  getStartOfWeek,
  getEndOfWeek,
  getDay,
  getStartOfMonth,
  getEndOfMonth,
  getStartOfYear,
  getEndOfYear,
} from "@/utils";
import {mapGetters} from "vuex";

export default {
  name: "Account",
  components: { AccountList, Operation, SkeletonList },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      typeSearch: "day",
      loadingStat: false,
      lableStat: "du jour",
      options: [
        {
          label: "Journalier",
          value: "day",
        },
        {
          label: "Hebdomadaire",
          value: "week",
        },
        {
          label: "Mensuel",
          value: "month",
        },
        {
          label: "Annuel",
          value: "year",
        },
      ],
      totalBalance: 0,
      totalCredit: 0,
      totalDebit: 0,
      totalCustomerDbt: 0,
      activeName: "OPERATION",
      listQuery: {
        start: getDay(),
        end: getDay(),
      },
    };
  },
  watch: {
    activeName(val) {
      this.$router.push(`${this.$route.path}?tab=${val}`);
    },
  },
  created() {
    const tab = this.$route.query.tab;

    if (tab) {
      this.activeName = tab;
    }
  },
  mounted() {
    this.initData();
  },
  computed :{
        ...mapGetters(["user"]),
    },
  methods: {
    initData() {
      this.loadingStat = true;
      setTimeout(() => {
        this.getAllBalances();
        this.fetchStasticData();
        this.loadingStat = false;
      }, 1.5 * 1000);
    },
    async getAllBalances() {
      await fetchBalances().then((res) => {
        this.totalBalance = res.data.total_balance;
        this.totalCustomerDbt = res.data.total_customer_debt;
      });
    },
    async fetchStasticData() {
      await fetchStastic(this.listQuery).then((res) => {
        this.totalCredit = res.data.total_credit;
        this.totalDebit = res.data.total_debit;
      });
    },
    HANDLE_PLAGE(e) {
      if (e === "day") {
        this.listQuery.start = getDay();
        this.listQuery.end = getDay();
        this.lableStat = "du jour";
      }
      if (e === "week") {
        this.listQuery.start = getStartOfWeek();
        this.listQuery.end = getEndOfWeek();
        this.lableStat = "de la semaine";
      }
      if (e === "month") {
        this.listQuery.start = getStartOfMonth();
        this.listQuery.end = getEndOfMonth();
        this.lableStat = "du mois";
      }
      if (e === "year") {
        this.listQuery.start = getStartOfYear();
        this.listQuery.end = getEndOfYear();
        this.lableStat = "de l'année";
      }
      this.fetchStasticData();
    },
  },
};
</script>

<style lang="scss" scoped></style>
